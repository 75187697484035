import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { SearchPlaceContext } from './../../context/SearchPlaceProvider';

let autoComplete;
const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();

      }
    }

  } else {
    script.onload = () => callback();
  }
  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
}

const BannerMap = () => {
  const { setSelectedPlace, selectedPlace, scriptIsLoaded, setIsScriptLoaded } = useContext(SearchPlaceContext);

  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  // const scriptIsLoaded = useRef(false);
  const [searchInput, setSearchInput] = useState("");
  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        types: ["geocode"],
      }
    )
    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    })
  }

  const checkSearchField = (e) => {
    setSearchInput(e.target.value);
  }

  const keyEventCheck = (e) => {
    if (e.key === "Enter") {
      if (searchInput.length === 0) {
        return toast.warning("Please enter a place!", { position: "top-right", autoClose: 3000 });
      }
      if (searchInput.length !== 0) {
        return toast.warning("Please select a place from suggestions!", { position: "top-right", autoClose: 3000 });
      }
    }
  }
  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();
    const queryAddress = addressObject?.formatted_address;
    updateQuery(queryAddress);
    setSelectedPlace({
      ...selectedPlace,
      lat: addressObject?.geometry?.location.lat(),
      lng: addressObject?.geometry?.location.lng(),
    })
    const latlng = {
      lat: addressObject?.geometry?.location.lat(),
      lng: addressObject?.geometry?.location.lng(),
    }
    if (queryAddress !== undefined && queryAddress !== "") {
      navigate(`/searchedplace/${queryAddress}`)
    }
  }
  useEffect(() => {
    console.log("script use effect called:", scriptIsLoaded.current);
    if (scriptIsLoaded.current !== true) { // Load only if not already loaded
      scriptIsLoaded.current = true;
      console.log("script current:", scriptIsLoaded.current);
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        () => {
          setIsScriptLoaded(true)
          handleScriptLoad(setQuery, autoCompleteRef);
          console.log("script loaded");
        }
      );
    } else {
      return;
    }
  }, []);


  console.log("BannerMap Hitting");
  return (
    <>
      <div className="relative flex items-center w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
        <div className="grid place-items-center h-full w-12 text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </div>
        <input
          ref={autoCompleteRef}
          type="text"
          name="search_field"
          id="search"
          className="search_control peer h-full w-full outline-none text-sm text-gray-700 pr-2"
          onChange={(e) => { setQuery(e.target.value); checkSearchField(e) }}
          placeholder="Search By city, place or trail name"
          onKeyDown={(e) => e.key === "Enter" && keyEventCheck(e)}
          value={query}
        />
      </div>
      <ToastContainer />
    </>
  )
}

export default BannerMap;

